import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-simple-flex-grid';

import Button from '../ButtonLink';
import Container from '../Container';
import {
    CenterOnBig,
    ContentInner,
    ContentSection,
    Icon,
    Image,
    ShowOnBig,
    ShowOnSmall,
    IconAndTitleWrapper,
} from './styled';
import { Heading1, Heading2, Heading3, Paragraph } from '../Typography';

// hooks
import useWindowResize from '../../../hooks/useWindowResize';

function renderHeading({ firstSectionHeading1, index, smallTitle, title }) {
    if (smallTitle) {
        return <Heading3>{title}</Heading3>;
    }

    if (firstSectionHeading1 && index === 0) {
        return <Heading1>{title}</Heading1>;
    }

    return <Heading2>{title}</Heading2>;
}

const Section = ({ alignment, section, lng, smallTitle, index, firstSectionHeading1 }) => {
    const [isMounted, setIsMounted] = useState(false);
    const { width } = useWindowResize({
        initialWidth: isMounted ? window.innerWidth : 0,
    });

    useEffect(() => {
        if (!isMounted) {
            setIsMounted(true);
        }
    }, [isMounted]);

    const image = section.image ? (
        <>
            {width < 992 ? (
                <Image
                    src={
                        section.imageSmall ? section.imageSmall.publicURL : section.image.publicURL
                    }
                    alt={section.title}
                />
            ) : (
                <Image src={section.image.publicURL} alt={section.title} />
            )}
        </>
    ) : null;

    let content = (
        <CenterOnBig>
            {renderHeading({ index, smallTitle, firstSectionHeading1, title: section.title })}
            <Paragraph>{section.description}</Paragraph>
            {section.button ? (
                <Button
                    label={section.button.label}
                    href={section.button.href}
                    lng={lng}
                    transparent
                    marginbottomonsmall="40px"
                    marginBottom="24px"
                />
            ) : null}
            {image}
        </CenterOnBig>
    );

    if (section.image && alignment === 'left') {
        content = (
            <Row align="middle">
                <Col xs={12} md={6}>
                    <ContentInner>
                        <IconAndTitleWrapper>
                            {section.icon ? (
                                <Icon src={section.icon.publicURL} alt={`${section.title} icon`} />
                            ) : null}
                            {renderHeading({
                                index,
                                smallTitle,
                                firstSectionHeading1,
                                title: section.title,
                            })}
                        </IconAndTitleWrapper>

                        <Paragraph>{section.description}</Paragraph>
                        <ShowOnSmall>{image}</ShowOnSmall>
                        {section.button ? (
                            <Button
                                label={section.button.label}
                                href={section.button.href}
                                lng={lng}
                                transparent
                            />
                        ) : null}
                    </ContentInner>
                </Col>
                <Col xs={{ span: 0 }} md={6}>
                    <ContentInner padding="0 0 0 90px">{section.image ? image : null}</ContentInner>
                </Col>
            </Row>
        );
    }

    if (section.image && alignment === 'right') {
        content = (
            <Row align="middle">
                <Col xs={{ span: 0 }} md={6}>
                    <ContentInner padding="0 90px 0 0">
                        <ShowOnBig>{image}</ShowOnBig>
                    </ContentInner>
                </Col>
                <Col xs={12} md={6}>
                    <ContentInner>
                        <IconAndTitleWrapper>
                            {section.icon ? (
                                <Icon src={section.icon.publicURL} alt={`${section.title} icon`} />
                            ) : null}
                            {renderHeading({
                                index,
                                smallTitle,
                                firstSectionHeading1,
                                title: section.title,
                            })}
                        </IconAndTitleWrapper>
                        <Paragraph>{section.description}</Paragraph>
                        <ShowOnSmall>{image}</ShowOnSmall>
                        {section.button ? (
                            <ShowOnSmall>
                                <Button
                                    label={section.button.label}
                                    href={section.button.href}
                                    lng={lng}
                                    transparent
                                />
                            </ShowOnSmall>
                        ) : null}
                        {section.button ? (
                            <ShowOnBig>
                                <Button
                                    label={section.button.label}
                                    href={section.button.href}
                                    lng={lng}
                                    transparent
                                />
                            </ShowOnBig>
                        ) : null}
                    </ContentInner>
                </Col>
            </Row>
        );
    }

    return (
        <ContentSection>
            <Container narrow>{content}</Container>
        </ContentSection>
    );
};

Section.defaultProps = {
    smallTitle: false,
    index: 1000,
    firstSectionHeading1: false,
};

Section.propTypes = {
    index: PropTypes.number,
    firstSectionHeading1: PropTypes.boolean,
    alignment: PropTypes.oneOf(['left', 'right', 'center']).isRequired,
    lng: PropTypes.string.isRequired,
    section: PropTypes.shape({
        icon: PropTypes.shape({
            publicURL: PropTypes.string.isRequired,
        }),
        title: PropTypes.string.isRequired,
        button: PropTypes.shape({
            label: PropTypes.string.isRequired,
            href: PropTypes.string.isRequired,
        }),
        description: PropTypes.string.isRequired,
        image: PropTypes.shape({
            publicURL: PropTypes.string.isRequired,
        }),
        imageSmall: PropTypes.shape({
            publicURL: PropTypes.string.isRequired,
        }),
    }).isRequired,
    smallTitle: PropTypes.bool,
};

export default Section;
