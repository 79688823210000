import styled, { css } from 'styled-components';

export const CenterOnBig = styled.div`
    text-align: center;

    a {
        margin-bottom: 24px !important;

        @media all and (max-width: 768px) {
            margin-bottom: 40px !important;
        }
    }

    @media all and (max-width: 991px) {
        margin-bottom: 60px;
    }
`;

export const ContentSection = styled.div`
    margin-bottom: 130px;

    h1,
    h2 {
        margin-bottom: 20px;
        line-height: 3.5rem;
    }
    h3 {
        margin-bottom: 16px;
    }
    a {
        margin: 0;
    }

    p {
        margin-bottom: 2em;
    }

    @media all and (max-width: 768px) {
        margin-bottom: 28px;

        h2 {
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 20px;
        }
    }
`;

export const ContentInner = styled.div`
    padding: ${({ padding }) => padding || 0};

    @media all and (max-width: 991px) {
        margin-bottom: 30px;
        text-align: center;
    }
`;

export const Icon = styled.img`
    display: block;
    margin-bottom: 20px;

    @media all and (max-width: 768px) {
        width: 40px;
        height: 40px;
    }
`;

export const Image = styled.img`
    display: block;

    ${({ hiddenLg }) =>
        hiddenLg &&
        css`
            @media all and (min-width: 992px) {
                display: none;
            }
        `}

    @media all and (max-width: 991px) {
        width: 50%;
        height: auto;
        margin-bottom: 20px;
    }

    @media all and (max-width: 601px) {
        width: 100%;
    }
`;

export const ShowOnBig = styled.div`
    @media all and (max-width: 991px) {
        display: none;
    }
`;

export const ShowOnSmall = styled.div`
    @media all and (min-width: 992px) {
        display: none;
    }

    @media all and (max-width: 991px) {
        text-align: center;
        img {
            display: inline-block;
        }
    }
`;

export const IconAndTitleWrapper = styled.div`
    @media all and (max-width: 991px) {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            margin-right: 15px;
        }
    }
`;
