import { useStateWithDeps } from 'use-state-with-deps';
import { useWindowResize, useThrottledFn } from 'beautiful-react-hooks';

export default function useWinResize({ throttleMS = 300, initialWidth = 0 }) {
    const [width, setWidth] = useStateWithDeps(initialWidth, [initialWidth]);

    const onWindowResizeHandler = useThrottledFn(() => {
        if (typeof window !== 'undefined') {
            setWidth(window.innerWidth);
        }
    }, throttleMS);

    useWindowResize(onWindowResizeHandler);

    return { width };
}
